import { useEffect, useState } from "react"
import { apiServices } from "../services"
import { useParams } from "react-router-dom"
import profileThumb from '../images/profile-thumb.svg'
import ToastMessage from "../components/ToastMessage"

function TransactionTable() {
    const [gamesData, setGamesData] = useState([])
    const [gamesList, setGamesList] = useState([])
    const params = useParams()
    const [pageError, setPageError] = useState(null)

    useEffect(() => {
        apiServices.getCircleGameDetails(params.id, params.gameId).then((response) => {
            if (!response.error && !response.code) {
                setGamesData(response.data)
                setGamesList(response.games)
            }
            if (response.error) setPageError(response.error)
            if (response.code) {
                if (response.response && response.response.data && response.response.data.message) {
                    setPageError(response.response.data.message)
                } else {
                    setPageError(response.message)
                }
            }
            setTimeout(() => {
                setPageError(null)
            }, 3000);
        })
    }, [params.id, params.gameId])

    const getNetAmount = (memberId, gameId) => {
        const member = gamesData.find(m => m.member._id === memberId);
        if (!member) return '';
        const game = member.games.find(g => g.gameId === gameId);
        return game ? game.net : '';
    }

    const getTotalNetAmount = (memberId) => {
        const member = gamesData.find(m => m.member._id === memberId);
        if (!member) return '';
        const game = member.games.reduce((a, b) => {
            return a += parseFloat(b.net)
        }, 0);
        return game ? game : '';
    }

    /*const getStartAmount = (memberId) => {
        const member = gamesData.find(m => m.member._id === memberId);
        if (!member || member.games.length <= 1) return '0'; // Handle case with no games or only one game
        const gamesExceptLast = member.games.slice(0, -1); // Exclude the last game
        const totalNetExceptLast = gamesExceptLast.reduce((total, game) => {
            return total + parseFloat(game.net);
        }, 0);
        return totalNetExceptLast.toFixed(2); // Return as a string with 2 decimal places
    };*/

    return (
        <div className='ongoing-game'>
            <div className='ongoing-table sticky-table'>
                <table className='table game-table'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Net Amt</th>
                            {/*<th>Start Amt</th>*/}
                            {gamesList.map((item, index) => <th key={index}>{item.name}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {gamesData.map((item, index) => <tr key={index}>
                            <td>
                                <div className='thumb-column'>
                                    <div className='image'>
                                        <img src={item.member.profileImage ? item.member.profileImage : profileThumb} alt='Profile' />
                                    </div>
                                    <div className='text'>
                                        <span>{item.member.name}</span>
                                    </div>
                                </div>
                            </td>
                            {<td className={getTotalNetAmount(item.member._id) > 0 ? 'success' : 'error'}>{getTotalNetAmount(item.member._id) > 0 ? '+' : ''}{(getTotalNetAmount(item.member._id)) || 0}</td>}
                            {/*<td className={getStartAmount(item.member._id) > 0 ? 'success' : 'error'}>{getStartAmount(item.member._id) > 0 ? '+' : ''}{getStartAmount(item.member._id) || 0}</td>*/}
                            {gamesList.map((game) => <td key={game._id}>
                                {getNetAmount(item.member._id, game._id) || 0}
                            </td>)}
                        </tr>)}
                    </tbody>
                </table>
            </div>
            {pageError !== null ? <ToastMessage color="red" duration={3000} icon="highlight_off">{pageError}</ToastMessage> : null}
        </div>
    );
}

export default TransactionTable;
