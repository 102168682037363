import { useEffect, useState } from "react"
import { apiServices } from "../services"
import { useParams } from "react-router-dom"
import ToastMessage from "../components/ToastMessage";

function SettleTransactionsTable() {
    const [transactionsData, setTransactionData] = useState([])
    const [settleTransactions, setSettleTransactions] = useState([])
    const params = useParams()
    const [pageError, setPageError] = useState(null)
    const [pageSuccess, setPageSuccess] = useState(null)

    /*const reduceArray = (arr) => {
        let output
        if (arr && arr.length > 0) {
            output = arr.reduce((a, b) => {
                return a += parseFloat(b)
            }, 0)
        } else {
            output = 0
        }
        return output
    }*/

    const getAmountTaken = () => {
        if (transactionsData) {
            const amountTaken = transactionsData.filter((amt) => {
                return amt.totalAmount > 0
            }).reduce((a, b) => {
                return a += b.totalAmount
            }, 0)
            return amountTaken
        }
    }

    const getAmountGiven = () => {
        if (transactionsData) {
            const amountTaken = transactionsData.filter((amt) => {
                return amt.totalAmount <= 0
            }).reduce((a, b) => {
                return a += b.totalAmount
            }, 0)
            return amountTaken
        }
    }

    const getTotalAmountToTake = (data, memberId) => {
        if (data && data.amountPaid.length > 0) {
            let amountTaken = data.amountPaid.find((item) => {
                return item.member === memberId
            })
            if(amountTaken) return amountTaken.totalPaid
            return '0'
        }
        return '0'
    }

    useEffect(() => {
        apiServices.GetCircleTransactions(params.id, params.gameId).then((response) => {
            if (!response.error && !response.code) {
                setTransactionData(response.transactions)
                const settleTransactionData = {
                    circle: params.id,
                    game: params.gameId,
                    transactions: response.transactions.map((item) => {
                        const transaction = { member: item.member ? item.member._id : '', totalAmount: item.totalAmount, games: item.game }
                        if (item.totalAmount > 0) {
                            transaction.amountReceived = []
                        } else {
                            transaction.amountPaid = []
                        }
                        return transaction
                    })
                }
                setSettleTransactions(settleTransactionData)
            }
            if (response.error) setPageError(response.error)
            if (response.code) {
                if (response.response && response.response.data && response.response.data.message) {
                    setPageError(response.response.data.message)
                } else {
                    setPageError(response.message)
                }
            }
            setTimeout(() => {
                setPageError(null)
            }, 3000);
        })
    }, [params.id, params.gameId])

    const handleSettlePayment = (value, payeeId, receiverId, maxAmount, maxPayeeAmount) => {
        if (value > maxPayeeAmount * -1) {
            setPageError('Value exceeds maximum amount payable');
            setTimeout(() => {
                setPageError(null);
            }, 2500);
            return;
        }
        const updatedTransactions = { ...settleTransactions };
        updatedTransactions.transactions = updatedTransactions.transactions.map(transaction => {
            const updatedTransaction = { ...transaction };
            if (transaction.member && transaction.member === receiverId) {
                const existingPayee = updatedTransaction.amountReceived.find(p => p.member === payeeId);
                if (existingPayee) {
                    existingPayee.totalPaid = value;
                } else {
                    updatedTransaction.amountReceived.push({ member: payeeId, totalPaid: value });
                }

                const currentTotal = updatedTransaction.amountReceived.reduce((acc, curr) => acc + parseFloat(curr.totalPaid), 0);
                if (currentTotal > maxAmount) {
                    setPageError(`Adding ${value} exceeds the maximum amount of ${maxAmount}.`);
                    setTimeout(() => {
                        setPageError(null);
                    }, 2500);
                    return transaction;
                }
            }

            if (transaction.member && transaction.member === payeeId) {
                const existingReceiver = updatedTransaction.amountPaid.find(p => p.member === receiverId);
                if (existingReceiver) {
                    existingReceiver.totalPaid = value;
                } else {
                    updatedTransaction.amountPaid.push({ member: receiverId, totalPaid: value });
                }

                const currentTotal = updatedTransaction.amountPaid.reduce((acc, curr) => acc + parseFloat(curr.totalPaid), 0);
                if (currentTotal > maxPayeeAmount * -1) {
                    setPageError(`Adding ${value} exceeds the maximum amount of ${maxAmount}.`);
                    setTimeout(() => {
                        setPageError(null);
                    }, 2500);
                    return transaction;
                }
            }

            return updatedTransaction;
        });

        setSettleTransactions(updatedTransactions);
    };

    const saveTransactionDetails = () => {
        apiServices.saveTransactionDetails(settleTransactions).then((response) => {
            if (!response.error && !response.code) {
                setPageSuccess('Transactions updated successfully')
            }
            if (response.error) setPageError(response.error)
            if (response.code) {
                if (response.response && response.response.data && response.response.data.message) {
                    setPageError(response.response.data.message)
                } else {
                    setPageError(response.message)
                }
            }
            setTimeout(() => {
                setPageError(null)
            }, 3000);
        })
    }

    return (
        <div className='ongoing-game'>
            <div className='ongoing-table transaction-details-table sticky-table'>
                <table className='table game-table'>
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            {transactionsData ? transactionsData.map((transaction, index) =>
                                transaction.totalAmount <= 0 ? <th key={index}>{Math.round(transaction.totalAmount)}</th> : null
                            ) : null}
                        </tr>
                        <tr>
                            <th>NAME</th>
                            <th>AMOUNT TAKEN</th>
                            <th>AMOUNT GIVEN</th>
                            {transactionsData ? transactionsData.map((transaction, index) =>
                                transaction.totalAmount <= 0 ? <th key={index}>{transaction.member ? transaction.member.name : null}</th> : null
                            ) : null}
                        </tr>
                        <tr>
                            <th>Net Amount</th>
                            <th>{Math.round(getAmountTaken())}</th>
                            <th>{Math.round(getAmountGiven())}</th>
                            {transactionsData ? transactionsData.map((transaction, index) =>
                                transaction.totalAmount <= 0 ? <th key={index}>{Math.round(transaction.totalAmount)}</th> : null
                            ) : null}
                        </tr>
                    </thead>
                    <tbody>
                        {transactionsData ?
                            transactionsData.map((transaction, index) => transaction.totalAmount > 0 ? <tr key={index}>
                                <td>{transaction.member ? transaction.member.name : null}</td>
                                <td className='success'>+{Math.round(transaction.totalAmount)}</td>
                                <td className='error'>-{Math.round(transaction.totalAmount)}</td>
                                {transactionsData ? transactionsData.map((transactionToTake, index) =>
                                    transactionToTake.totalAmount <= 0 ? <td key={index}><input type="text" className="form-control" name={`${transaction.member ? transaction.member.name.split(' ').join('') : null}-${transactionToTake.member ? transactionToTake.member.name.split(' ').join('') : null}`}
                                        defaultValue={transactionToTake.amountPaid && transactionToTake.amountPaid.length > 0 && transactionToTake.member ? getTotalAmountToTake(transactionToTake, transaction.member._id) : 0}
                                        onChange={(e) => {handleSettlePayment(e.target.value, transactionToTake.member._id, transaction.member ? transaction.member._id : '', transaction.totalAmount, transactionToTake.totalAmount)}} readOnly={transactionToTake.status && transactionToTake.status === 'completed' ? true : false} /></td> : null
                                ) : null}
                            </tr> : null)
                            : null}
                    </tbody>
                </table>
            </div>
            {transactionsData && transactionsData.length > 0 && transactionsData[0].status !== 'completed' && <div className='absolute-center'><button className="green-btn" onClick={saveTransactionDetails}>Save</button></div>}
            {pageError !== null ? <ToastMessage color="red" duration={3000} icon="highlight_off">{pageError}</ToastMessage> : null}
            {pageSuccess !== null ? <ToastMessage color="green" duration={3000} icon="check_circle">{pageSuccess}</ToastMessage> : null}
        </div>
    );
}

export default SettleTransactionsTable;
