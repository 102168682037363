import { useState } from "react";
import deleteIcon from '../images/delete-icon.svg'
import minusIcon from '../images/minus-icon.svg'
import plusIcon from '../images/plus-icon.svg'

function GameMemberRow({field, memberKey, circleInfo, gameMembers, handleGameMembers, OnHandleRemoveField}) {
    const [filteredUsers, setFilteredUsers] = useState([])

    const handlePriceIncrease = (key) => {
        const newGameMembers = [...gameMembers];
        const currentBuyIn = parseFloat(newGameMembers[key].buyIn) || 0;
        newGameMembers[key].buyIn = (currentBuyIn + 100).toString();
        handleGameMembers(newGameMembers);
    }

    const handlePriceDecrease = (key) => {
        const newGameMembers = [...gameMembers];
        const currentBuyIn = parseFloat(newGameMembers[key].buyIn) || 0;
        if(currentBuyIn > 100){
            newGameMembers[key].buyIn = (currentBuyIn > 0 ? currentBuyIn - 100 : 0).toString();
            handleGameMembers(newGameMembers);
        }
    }
    return (
        <div key={memberKey} className='addmember-row'>
            <div className="input-wrapper dropdown-input">
                <input type="text" name="name" className="form-control" autoComplete="off" placeholder="e.g. Pawan Sharma"
                onChange={(e) => {
                    const filteredMembers = circleInfo.members.filter((user) => {
                        return user.name?.toLowerCase().includes(e.target.value.toLowerCase())
                    })
                    setFilteredUsers(filteredMembers)
                    gameMembers[memberKey].name = e.target.value
                }} value={field.name} />
                {filteredUsers && <ul className="input-dropdown">
                    {filteredUsers.map((user, filteredindex) =>
                        <li key={filteredindex} onClick={() => {
                            const newGameMembers = [...gameMembers];
                            newGameMembers[memberKey].name = user.name;
                            newGameMembers[memberKey].id = user._id
                            handleGameMembers(newGameMembers);
                            setFilteredUsers(null)
                        }}>{user.name}</li>
                    )}
                </ul>}
            </div>
            <div className="input-wrapper number-input">
                <span className="minus" onClick={() => handlePriceDecrease(memberKey)}><img src={minusIcon} alt="Minus" /></span>
                <input type="text" name="buyIn" autoComplete="off" className="form-control" id="buyInControl" readOnly value={field.buyIn} />
                <span className="plus" onClick={() => handlePriceIncrease(memberKey)}><img src={plusIcon} alt="Plus" /></span>
            </div>
            <span className='delete-icon' onClick={() => OnHandleRemoveField(memberKey)}><img src={deleteIcon} alt="delete icon" /></span>
        </div>
    );
}

export default GameMemberRow;
