import { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid'
import checkIcon from '../images/check-icon.svg'
import closeIcon from '../images/close-modal.svg'
import ToastMessage from "../components/ToastMessage";
import { apiServices } from "../services";
import AddMemberRow from "./AddMemberRow";
import { isMobile } from 'react-device-detect';
import { CopyToClipboard } from 'react-copy-to-clipboard';

function AddCircle({ toggleModal, circleUpdated }) {
    const location = window.location.origin
    const [circleToken] = useState(() => uuidv4());
    const inviteUrl = location + `/${circleToken}`
    const colors = [['#FFCEEE', '#A3BCFF'], ['#CDFCFF', '#82A6FF'], ['#D0BAFF', '#BACDFF'], ['#FFF4BA', '#FFCFBA'], ['#FFBDF4', '#FF888C'], ['#CDBBFF', '#DD7DFF']]
    const [selectedColor, setSelectedColor] = useState(JSON.stringify(colors[0]))
    const [pageError, setPageError] = useState(null)
    const [pageSuccess, setPageSuccess] = useState(null)
    const [usersList, setUsersList] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [linkText, setLinkText] = useState('Send Link')
    const [circleMembers, setCircleMembers] = useState([{
        id: '',
        emailAddress: '',
        memberName: ''
    }])

    const [circleName, setCircleName] = useState('')

    const handleColorChange = (color) => {
        setSelectedColor(color);
    };

    const handleCircleName = (e) => {
        setCircleName(e.target.value);
    }

    const handleInvite = async () => {
        try {
            if (isMobile && navigator.share !== undefined) {
                await navigator.share({
                    title: `You are invited to WeParty Circle${circleName ? ' - ' + circleName : ''}.`,
                    url: inviteUrl,
                });
            } else {
                setLinkText('Copied')
                setTimeout(() => {
                    setLinkText('Send Link')
                }, 1000);
            }
        } catch (error) {
            console.log('Error in sharing or copying the URL: ', error);
        }
    }

    const handleAddField = () => {
        const last_entry = circleMembers[circleMembers.length - 1]
        if ((last_entry && last_entry.emailAddress && last_entry.memberName) || circleMembers.length === 0) {
            setCircleMembers([
                ...circleMembers,
                { emailAddress: '', memberName: '' }
            ]);
        } else {
            setPageError('Please fill previous entry to add more.')
        }
        setTimeout(() => {
            setPageError(null)
        }, 3000);
    }
    const handleRemoveField = (indexToRemove) => {
        setCircleMembers(circleMembers.filter((_, index) => index !== indexToRemove));
    }
    const handleCircleMembers = (member) => {
        setCircleMembers(member)
    }
    useEffect(() => {
        const getUsers = () => {
            apiServices.getUsers().then((response) => {
                if (response.code) {
                    if(response.response && response.response.data && response.response.data.message){
                        setPageError(response.response.data.message)
                    } else{
                        setPageError(response.message)
                    }
                } else if (response.error) {
                    setPageError(response.message)
                } else if (!response.error && !response.code) {
                    setUsersList(response.data)
                }
            })
        }
        if (usersList === null) getUsers()
    }, [usersList])
    const createCircle = () => {
        if (circleName === '' || circleName === null || circleName === undefined) {
            setPageError('Circle name is required')
        } else if (selectedColor === '' || selectedColor === null || selectedColor === undefined) {
            setPageError('Color need to be selected')
        } else if (circleMembers.length === '' || (circleMembers.length === 1 && (circleMembers[0].emailAddress === '' || circleMembers[0].name === ''))) {
            setPageError('Add at least one member')
        } else {
            setIsLoading(true)
            apiServices.createCircle({ circleName, selectedColor, circleMembers, circleToken }).then((response) => {
                if (response.code) {
                    setPageError(response.message)
                } else if (response.error) {
                    setPageError(response.message)
                } else if (!response.error && !response.code) {
                    setPageSuccess(response.message)
                    setTimeout(() => {
                        toggleModal(false)
                        circleUpdated()
                    }, 2000);
                }
                setIsLoading(false)
            })
        }
        setTimeout(() => {
            setPageError(null)
            setPageSuccess(null)
        }, 3000);
    }
    return (
        <div className='circle-modal'>
            <div className="circle-modal-wrapper fade-in-up-modal">
                <img src={closeIcon} alt="close modal" className="close-modal" onClick={() => toggleModal(false)} />
                <h3>Create New Circle</h3>
                <div className="circle-name">
                    <div className="input-wrapper">
                        <label htmlFor="circleNameControl" className="form-label">Enter circle name</label>
                        <input type="text" name="circleName" className="form-control" id="circleNameControl" placeholder='e.g. Weekend Fun' autoComplete='off'
                            onChange={handleCircleName}
                            value={circleName}
                        />
                    </div>
                    <div className="input-wrapper color-input">
                        <label htmlFor="circleColorControl" className="form-label">Select circle colour</label>
                        <ul className="circle-radios">
                            {colors.map((color, index) =>
                                <li key={index}>
                                    <input type="radio" id={color[0].substring(1)} name="color" className="color-radio" value={JSON.stringify(color)} checked={selectedColor === JSON.stringify(color)} onChange={() => handleColorChange(JSON.stringify(color))} />
                                    <label htmlFor={color[0].substring(1)} style={{ background: `linear-gradient(95.77deg, ${color[0]} 3.78%, ${color[1]} 97.89%)` }}></label>
                                    {selectedColor === JSON.stringify(color) ? <img src={checkIcon} className="check-icon" alt="check icon" /> : null}
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className="circle-members">
                        <label htmlFor="circleMembersControl" className="form-label">Add circle members</label>
                        <p className="invite-para">Invite users to circle via Link? <CopyToClipboard text={inviteUrl} onCopy={handleInvite}><span className="link">{linkText}</span></CopyToClipboard></p>
                        <div className="member-header lg-header">
                            <div>Enter Email Address</div>
                            <div>Enter Member Name</div>
                        </div>
                        <div className="member-header mobile-header">Enter email address & name</div>
                        {circleMembers.map((field, index) => (
                            <AddMemberRow field={field} key={index} memberkey={index} usersList={usersList} OnHandleRemoveField={handleRemoveField} circleMembers={circleMembers} handleCircleMembers={handleCircleMembers} />
                        ))}
                    </div>
                    <div className="action-btns">
                        <span className="material-icons-outlined add-member-icon" onClick={handleAddField}>add</span>
                        <button className={`green-btn no-shadow ${isLoading ? 'disabled' : ''}`} onClick={createCircle}>Create Circle</button>
                    </div>
                </div>
            </div>
            {pageError !== null ? <ToastMessage color="red" duration={3000} icon="highlight_off">{pageError}</ToastMessage> : null}
            {pageSuccess !== null ? <ToastMessage color="green" duration={3000} icon="check_circle">{pageSuccess}</ToastMessage> : null}
        </div>
    );
}

export default AddCircle;
