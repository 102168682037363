import { useState } from 'react';
import deleteIcon from '../images/delete-icon.svg'

function AddMemberRow({ field, memberkey, usersList, OnHandleRemoveField, circleMembers, handleCircleMembers }) {
    const [filteredUsers, setFilteredUsers] = useState(null)
    return (
        <div key={memberkey} className='addmember-row'>
            <div className="input-wrapper dropdown-input">
                <input type="text" name="emailAddress" className="form-control" placeholder="e.g. pawansharma@gmail.com"
                    onChange={(e) => {
                        const newCircleMembers = [...circleMembers];
                        delete newCircleMembers[memberkey].id
                        newCircleMembers[memberkey].emailAddress = e.target.value.toLowerCase();
                        handleCircleMembers(newCircleMembers);
                        const filteredMembers = usersList.filter((user) => {
                            return user.email.toLowerCase().includes(e.target.value.toLowerCase())
                        })
                        setFilteredUsers(filteredMembers)
                    }} value={field.emailAddress.toLowerCase()} autoComplete='off' />
                {filteredUsers && <ul className="input-dropdown">
                    {filteredUsers.map((user, filteredindex) =>
                        <li key={filteredindex} onClick={() => {
                            const newCircleMembers = [...circleMembers];
                            newCircleMembers[memberkey].emailAddress = user.email.toLowerCase();
                            newCircleMembers[memberkey].memberName = user.name
                            newCircleMembers[memberkey].id = user._id
                            handleCircleMembers(newCircleMembers);
                            setFilteredUsers(null)
                        }}>{user.email.toLowerCase()}</li>
                    )}
                </ul>}
            </div>
            <div className="input-wrapper">
                <input type="text" name="class" className="form-control" id="classControl" placeholder="e.g. Pawan Sharma"
                readOnly={usersList && usersList.filter((user) => user.email.toLowerCase()===field.emailAddress.toLowerCase()).length === 0 ? false : true}
                    onChange={(e) => {
                        const newCircleMembers = [...circleMembers];
                        newCircleMembers[memberkey].memberName = e.target.value;
                        handleCircleMembers(newCircleMembers);
                    }} value={field.memberName} autoComplete='off' />
            </div>
            <span className='delete-icon' onClick={() => OnHandleRemoveField(memberkey)}><img src={deleteIcon} alt="delete icon" /></span>
        </div>
    );
}

export default AddMemberRow;
